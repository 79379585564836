/*Header */
// jQuery(document).ready(function($) {
//   // Cache the image element and the current image source
//   var $changeImage = $('#changeimage');
//   var currentImageSrc = '';
//
//   // Handle hover on menu items
//   $('.headermenu li').hover(function() {
//     var dataImage = $(this).data('image');
//     if (dataImage !== currentImageSrc) {
//       $changeImage.attr('src', dataImage);
//       currentImageSrc = dataImage;
//     }
//   }, function() {
//     // Clear the image source when the mouse leaves the menu item
//     if (currentImageSrc !== '') {
//       $changeImage.attr('src', currentImageSrc);
//     }
//   });
//
//   // Initialize variables for animation
//   var mouseX = 0;
//   var mouseY = 0;
//   var imageX = 0;
//   var imageY = 0;
//   var imageWidth = $changeImage.width();
//   var imageHeight = $changeImage.height();
//   // Function to update mouse position
//   function updateMousePosition(e) {
//     mouseX = e.clientX;
//     mouseY = e.clientY;
//   }
//
//   // Function to smoothly move the image
//   function moveImage() {
//     var dx = (mouseX - imageX) * 0.05 - imageWidth/2;
//     var dy = (mouseY - imageY) * 0.05 - imageHeight/2;
//
//     imageX += dx;
//     imageY += dy;
//
//     $changeImage.css({
//       transform: `translate(${imageX}px, ${imageY}px)`,
//     });
//
//     requestAnimationFrame(moveImage);
//   }
//
//   // Attach mousemove event listener to update the mouse position
//   $(document).on('mousemove', updateMousePosition);
//
//   // Start the animation
//   requestAnimationFrame(moveImage);
// });

/**/

// jQuery(document).ready(function($) {
// $( "#menu-main-menu li").each(function( index ) {
//     var img_src=$(this).find('a').find('img').attr('src');
//     if(index==1){
//       $('#changeimage').attr('src',img_src);
//     }
// });
// });
// $( "#menu-main-menu li").each(function( index ) {
//       var img_src=$(this).find('a').find('img').attr('src');
//       $(this).attr('data-image',img_src);
// });

/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 40) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
  if ($('.scroll-image').length) { 
    // if the user scrolled even a little from the top of the page
    // if ($(document).scrollTop() > 1) {
      if (!$('.scroll-image').hasClass('scrolled')) {
        // set window top to 0
        $(window).scrollTop(0);
        $('.scroll-image').addClass('scrolled');
        $('#landing.default .title h1').addClass('scrolled');
        $('body').css('overflow-y', 'hidden');
        setTimeout(function() {
          $('body').css('overflow-y', 'scroll');
        }, 1000);
      // }
    } 
    // else {
    //   $('.scroll-image').removeClass('scrolled');
    // }
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});


/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Open Menu*/
$(".menu_button").on('click', function() {

      if($(this).hasClass('is-active')){
          //$('#changeimage').css('opacity','0');
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.menu_button').removeClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          $('.headermenu').removeClass('is-active');
          $('.header_wrapper').removeClass('openmenu');
          //$('.menu li').removeClass('aos-animate');
        }
        else{
        //  $('#changeimage').css('opacity','1');
          $(this).addClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
          $('.header_wrapper').addClass('openmenu');
      }


});

// Apply fitty to all elements with class of fitty-text
document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.fitty-text').forEach((el) => {
    fitty(el);
  })
});

import Swiper from 'swiper';

new Swiper(".home_accommodation_swiper", {
  slidesPerView: 4,
  grabCursor: true,
  spaceBetween: 12,
  breakpoints: {
    1024: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 2.5,
    },
    320: {
      slidesPerView: 1.2,
    },
  }
});

new Swiper(".about_villa_swiper", {
  slidesPerView: 'auto',
  grabCursor: true,
  spaceBetween: 12,
});
const locationSightsSwiper = new Swiper(".location_sights_swiper", {
  slidesPerView: 1,
  grabCursor: true,
  spaceBetween: 12,
  breakpoints: {
    450: {
      slidesPerView: 'auto',
    },
  }
});
// check if current page has villainfoswiper
if (document.querySelector('.location_sights_swiper') != null) {
  locationSightsSwiper.on('slideChange', function () {
    document.querySelector('.current-index-sights').textContent = locationSightsSwiper.activeIndex + 1;
  });
// Update swiper amount and title
  document.querySelector('.swiper-amount-sights').textContent = locationSightsSwiper.slides.length;
}

new Swiper(".villa_swiper", {
  slidesPerView: 4,
  grabCursor: true,
  spaceBetween: 12,
  breakpoints: {
    1024: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 2.5,
    },
    320: {
      slidesPerView: 1.2,
    },
  }
});

const villaInfoSwiper = new Swiper(".villa_info_swiper", {
  slidesPerView: 1,
  grabCursor: true,
  spaceBetween: 12,
});

// check if current page has villainfoswiper
if (document.querySelector('.villa_info_swiper') != null) {
  villaInfoSwiper.on('slideChange', function () {
    document.querySelector('.current-index').textContent = villaInfoSwiper.activeIndex + 1;
  });
// Update swiper amount and title
  document.querySelector('.swiper-amount').textContent = villaInfoSwiper.slides.length;
  document.querySelector('.swiper-title').textContent = villaInfoSwiper.slides[villaInfoSwiper.activeIndex].querySelector('.swiper-slide-caption').textContent;
  
  // Update swiper title
  villaInfoSwiper.on('slideChange', function () {
    var currentSlide = villaInfoSwiper.slides[villaInfoSwiper.activeIndex];
    var caption = currentSlide.querySelector('.swiper-slide-caption').textContent;
    document.querySelector('.swiper-title').textContent = caption;
  });
}

new Swiper(".villa_amenity_swiper", {
  grabCursor: true,
  breakpoints: {
    1440: {
      spaceBetween: 180,
    },
    1024: {
      spaceBetween: 100,
      slidesPerView: 1.2,
    },
    320: {
      slidesPerView: 1,
    },
  }
});

// add numbers between nav menu items
const menuItems = document.querySelectorAll('#menu-navigation-main-menu-1 li');

if (menuItems.length > 0) {
  menuItems.forEach((item, index) => {
    const numberElement = document.createElement('span');
    numberElement.classList.add('nav-menu-item-counter');
    const number = index + 1;
    numberElement.textContent = '0' + number;
    const menuItemContent = item.firstChild;
    item.insertBefore(numberElement, menuItemContent);
  })
}

const locationBeachesSwiper = new Swiper(".beaches_info_swiper", {
  slidesPerView: 1,
  grabCursor: true,
  spaceBetween: 12,
});

// check if current page has locationBeachesSwiper
if (document.querySelector('.beaches_info_swiper') != null) {
  locationBeachesSwiper.on('slideChange', function () {
    document.querySelector('.current-index').textContent = locationBeachesSwiper.activeIndex + 1;
  });
// Update swiper amount and title
  const beachTitle = document.querySelector('.beach-title');
  const swiperTitle = document.querySelector('.swiper-title');
  const beachText1 = document.querySelector('.beach-text1');
  const beachText2 = document.querySelector('.beach-text2');

  document.querySelector('.swiper-amount').textContent = locationBeachesSwiper.slides.length;


  beachTitle.textContent = locationBeachesSwiper.slides[locationBeachesSwiper.activeIndex].querySelector('.swiper-slide-title').textContent;
  swiperTitle.textContent = locationBeachesSwiper.slides[locationBeachesSwiper.activeIndex].querySelector('.swiper-slide-title').textContent;
  beachText1.textContent = locationBeachesSwiper.slides[locationBeachesSwiper.activeIndex].querySelector('.swiper-slide-text1').textContent;
  beachText2.textContent = locationBeachesSwiper.slides[locationBeachesSwiper.activeIndex].querySelector('.swiper-slide-text2').textContent;
  
  // Update swiper details
  locationBeachesSwiper.on('slideChange', function () {
    var currentSlide = locationBeachesSwiper.slides[locationBeachesSwiper.activeIndex];
    gsap.to(beachTitle, {duration: 0.2, opacity: 0, onComplete: function() {
      beachTitle.textContent = currentSlide.querySelector('.swiper-slide-title').textContent;
      gsap.to(beachTitle, {duration: 0.2, opacity: 1});
    }});
    gsap.to(swiperTitle, {duration: 0.2, opacity: 0, onComplete: function() {
      swiperTitle.textContent = currentSlide.querySelector('.swiper-slide-title').textContent;
      gsap.to(swiperTitle, {duration: 0.2, opacity: 1});
    }});
    gsap.to(beachText1, {duration: 0.2, opacity: 0, onComplete: function() {
      beachText1.textContent = currentSlide.querySelector('.swiper-slide-text1').textContent;
      gsap.to(beachText1, {duration: 0.2, opacity: 1});
    }});
    gsap.to(beachText2, {duration: 0.2, opacity: 0, onComplete: function() {
      beachText2.textContent = currentSlide.querySelector('.swiper-slide-text2').textContent;
      gsap.to(beachText2, {duration: 0.2, opacity: 1});
    }});
  });

}

// add numbers in publications
const publications = document.querySelectorAll('.publication');

if (publications.length > 0) {
  publications.forEach((item, index) => {
    const numberElement = document.createElement('span');
    numberElement.classList.add('publication-counter');
    const number = index + 1;
    if (number < 10) {
      numberElement.textContent = '0' + number;
    } else {
      numberElement.textContent = number;
    }
    // insert number under item's .title-wrapper > .title
    const titleWrapper = item.querySelector('.title-wrapper');
    const title = item.querySelector('.title');
    titleWrapper.insertBefore(numberElement, title);
  })
}


publications.forEach((el) => {
  const publicationContent = el.querySelector('.content-wrapper');
  const publicationContentHeight = publicationContent.scrollHeight;
  publicationContent.style.maxHeight = '0px';
  el.addEventListener('click', () => {
    console.log(publicationContent);
    if (!publicationContent.classList.contains('active')){
      console.log(publicationContent.scrollHeight)
      publicationContent.style.maxHeight = publicationContentHeight + 'px';
      publicationContent.classList.add('active');
      el.querySelector('.publication-img').classList.add('active');
    } else {
      publicationContent.style.maxHeight = '0px';
      publicationContent.classList.remove('active');
      el.querySelector('.publication-img').classList.remove('active');
    }
  })
})


// filter partners that have a child element with class .partner-image
const gsapPublications = Array.from(publications).filter(el => el.querySelector('.publication-img'))
var winWidth = window.innerWidth;

const enablePublicationsGSAP = () => {
  if(winWidth < 789){
    console.log('mobile view');
  }
    else
  {
    gsapPublications.forEach(addMouseEnter)
    gsapPublications.forEach(addMouseLeave)
    gsapPublications.forEach(addMouseMove)
  }
}

const addMouseEnter = (el) => {
  el.addEventListener('mouseenter', enterHandler)
}

const addMouseLeave = (el) => {
  el.addEventListener('mouseleave', leaveHandler)
}

const addMouseMove = (el) => {
  el.addEventListener('mousemove', moveHandler)
}

const removeMouseEvents = (el) => {
  el.removeEventListener('mouseenter', enterHandler)
  el.removeEventListener('mouseleave', leaveHandler)
  el.removeEventListener('mousemove', moveHandler)
}

const enterHandler = (e) => {
  const image = e.currentTarget.querySelector('.publication-img img')
  gsap.to(image, { autoAlpha: 1 });
}

const leaveHandler = (e) => {
  const image = e.currentTarget.querySelector('.publication-img img')
  gsap.to(image, { autoAlpha: 0 });
}

const moveHandler = (e) => {
  const image = e.currentTarget.querySelector('.publication-img img')
  const rect = image.getBoundingClientRect();
  const imageCenterX = rect.left + rect.width / 2;
  const imageCenterY = rect.top -30;
  gsap.to(image,{
      x: e.clientX - imageCenterX,
      y: e.clientY - imageCenterY,
      duration: 1,
      ease: 'power2.out',
  })
}

enablePublicationsGSAP();


if ($(window).width() > 768) {
    $( ".element" ).click(
        function() {
            $( this ).addClass( "hover" );
            $( this ).removeClass( "non-hover" );
            $('.element').not(this).removeClass( "hover" );
            $('.element').not(this).addClass( "non-hover" );
        }
    );

    $('.element').first().click();
}

if (window.innerWidth > 768) {
  const homeServicesListItems = document.querySelectorAll('.home-services-list-item');
  const homeServicesImageWrappers = document.querySelectorAll('.home-services-image-wrapper');
  if (homeServicesListItems.length > 0) {
    const div = document.querySelector(`[data-item="${homeServicesListItems[0].textContent}"]`);
    div.classList.add('active');
  }
  homeServicesListItems.forEach((item) => {
    item.addEventListener('mouseenter', (e) => {
      const div = document.querySelector(`[data-item="${e.target.textContent}"]`);
      div.classList.add('active');
      //remove active from other items
      homeServicesImageWrappers.forEach((item) => {
        if (item !== div) {
          item.classList.remove('active');
        }
      })
    })
  })
}

if (window.innerWidth > 768) {
  let clientX = -100;
  let clientY = -100;
  let lastX = -100;
  let lastY = -100;
  const cursor = document.querySelector('.cursor')
  const swipers = document.querySelectorAll('.swiper.swiper-cursor')

  // Show/hide the cursor when it is over the swipers
  if (swipers) {
    swipers.forEach((swiper) => {
      const swiperSlides = swiper.querySelectorAll('.swiper-slide');
      
      swiper.addEventListener('mouseenter', () => {
        cursor.classList.add('visible')
        if (swiper.classList.contains('blue-cursor')) {
          cursor.classList.add('blue')
        } else {
          cursor.classList.remove('blue')
        }
        if (swiperSlides.length < 3) {
          cursor.classList.add('two-dots')
        } else {
          cursor.classList.remove('two-dots')
        }
      })
      
      swiper.addEventListener('mouseleave', () => {
        cursor.classList.remove('visible')
        cursor.classList.remove('first-slide')
        cursor.classList.remove('last-slide')
      })
      
      swiperSlides.forEach((slide, index) => {
        slide.addEventListener('mouseenter', () => {
          console.log(index)
          console.log(swiperSlides.length)
          if (index === 0) {
            cursor.classList.add('first-slide')
          } else if (index === swiperSlides.length - 1) {
            cursor.classList.add('last-slide')
          }
        })
    
        slide.addEventListener('mouseleave', () => {
          cursor.classList.remove('first-slide')
          cursor.classList.remove('last-slide')
        })
      })
    })
  }

  // function for linear interpolation of values
  const lerp = (a, b, n) => {
    return (1 - n) * a + n * b;
  };

  const initCursor = () => {
    if (!cursor) return

    // add listener to track the current mouse position
  document.addEventListener('mousemove', e => {
      clientX = e.clientX;
      clientY = e.clientY;
    });

    // transform the cursor to the current mouse position
    // use requestAnimationFrame() for smooth performance
    const render = () => {
      // lesser delta, greater the delay that the custom cursor follows the real cursor
      const delta = 0.1;
      lastX = lerp(lastX, clientX, delta);
      lastY = lerp(lastY, clientY, delta);

      cursor.style.transform = `translate(${lastX}px, ${lastY}px)`;

      requestAnimationFrame(render);
    };

    requestAnimationFrame(render);
  };

  initCursor();

}


